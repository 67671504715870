import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"


class speakingPage extends React.Component 
{
    render() {
      const { data } = this.props
      const siteTitle = data.site.siteMetadata.title
      const menu = data.site.siteMetadata.menuLinks
      const tagline = data.site.siteMetadata.tagline

        return    (
          
            <Layout location="/speaking" title={siteTitle} menuLinks={menu} tagline={tagline}>
            <SEO title="Derek Featherstone: teaching and speaking" />
            <section>
            <h1>Speaking</h1>
            <p>There’s a very good chance I’d be interested in speaking at your event. Here’s what I like speaking about:</p>
            <ul>
            <li>inclusive design</li>
            <li>accessibility</li>
            <li>remote work</li>
            <li>building a great team</li>
            <li>team management</li>
            <li>growing and marketing a business</li>
            <li>teaching and speaking skills &amp; strategies</li>
            </ul>
            <p>I (mostly) speak about accessibility and inclusive design, UX, service design,  and web design &amp; development, and I do it really really well. I’m actively looking to branch out and speak in other areas. That’s numbers 3 through 6 above.</p>
            <h3>Get me to speak at your event</h3>
            <p>Get in touch. Let’s connect and talk about your event and how I fit in. I will always bring you my best.  I’ve been teaching since I was 22 years old, and I’m… umm… well… I’m more than twice that age now (we’ll leave it at that!) I’ve won awards and I consistently get awesome feedback on my talks and workshops from participants.</p>
            <p>Speaking and leading workshops has taken me all around the world. I’ve spoken at conferences and delivered workshops in Australia (Web Directions, Edge of the Web, OZeWAI), New Zealand (Webstock), the UK (dconstruct, UX Live, Edge Conference, @media), Netherlands (fronteers), Norway (Webdagene), Portugal (UX Lisbon), and all over North America (UX Week, An Event Apart, and many many more). There might be some other places too, but I’ll have to dig deep to remember them all. Many of these conferences had me back to speak multiple times, and I take that as the ultimate compliment!</p>
            </section>
            </Layout>
        )
        

    }
}







export default speakingPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        tagline
        menuLinks {
          name
          link
        }
      }
    }
  }
`